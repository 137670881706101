<template>
  <div class="view-wrapper" v-if="isPreviewShow">
    <mobile-preview
      :isShow="isPreviewShow"
      :title="title"
      @update:isShow="updateIsShow"
    >
      <div class="phone-wrap" slot="content">
        <div class="wrapper">
          <div class="header">
            <div class="header-title">{{ detail.title }}</div>
            <div class="header-subtitle">
              <div class="sub-wrap">
                <div class="sub-wrap_icon">
                  <img :src="require('./img/people.png')" alt="" />
                </div>
                <div class="sub-wrap_text">参与人数</div>
                <div class="sub-wrap_num">{{ detail.answerCount }}</div>
              </div>
              <!-- <div class="sub-tag">{{ hasAnswerMap[detail.hasAnswer] }}</div> -->
            </div>
          </div>
          <div class="main">
            <div
              class="content-wrap"
              v-for="(item, index) in filterList"
              :key="index"
            >
              <div class="content-wrap_title">
                {{ `${index + 1}、${item.title}` }}
                <template v-if="item.quType === 2">
                  {{
                    `${
                      item.minSelection
                        ? `最少选择${item.minSelection}项，`
                        : ""
                    }${
                      item.maxSelection ? `最多选择${item.maxSelection}项` : ""
                    }（多选）`
                  }}
                </template>
              </div>
              <div class="content-wrap_type" v-show="item.quType">
                <!-- 单选 -->
                <div class="single" v-if="item.quType === 1">
                  <div
                    class="option-wrap"
                    v-for="(opt, oIndex) in item.optionList"
                    :key="oIndex"
                  >
                    <div class="option-progress"></div>
                    <div class="option-text_copy">
                      {{ `${opt.optionKey}、${opt.optionTitle || ""}` }}
                    </div>
                    <div class="option-text">
                      {{ `${opt.optionKey}、${opt.optionTitle || ""}` }}
                    </div>
                    <div class="option-icon">
                      <img :src="require('./img/check.png')" />
                    </div>
                  </div>
                </div>
                <!-- 多选 -->
                <div class="multi" v-if="item.quType === 2">
                  <div
                    class="option-wrap"
                    v-for="(opt, oIndex) in item.optionList"
                    :key="oIndex"
                  >
                    <div class="option-progress"></div>
                    <div class="option-text_copy">
                      {{ `${opt.optionKey}、${opt.optionTitle || ""}` }}
                    </div>
                    <div class="option-text">
                      {{ `${opt.optionKey}、${opt.optionTitle || ""}` }}
                    </div>
                    <div class="option-icon">
                      <img :src="require('./img/check.png')" />
                    </div>
                  </div>
                </div>
                <!-- 输入 -->
                <div class="input" v-if="item.quType === 3">
                  <!-- <div class="option-wrap"> -->
                  <v-input
                    disabled
                    v-for="(opt, oIndex) in item.optionList"
                    :key="oIndex"
                    class="textarea"
                    type="textarea"
                    v-model="opt.content"
                  />
                  <!-- </div> -->
                </div>
                <!-- 上传 -->
                <div class="upload" v-if="item.quType === 4">
                  <!-- 代确认样式 -->
                </div>
                <!-- 矩阵量表 -->
                <div class="scale" v-if="item.quType === 5">
                  <div
                    class="option-wrap"
                    v-for="(opt, oIndex) in item.optionList"
                    :key="oIndex"
                  >
                    <div class="option-text">
                      {{ `${opt.optionKey}、${opt.optionTitle || ""}` }}
                    </div>
                    <div class="option-block">
                      <div
                        class="block-color"
                        v-for="(item, index) in scaleColorOps"
                        :key="index"
                        :style="{
                          backgroundColor:
                            opt.checkedPoints >= index + 1 ? item : '',
                          borderColor:
                            opt.checkedPoints >= index + 1 ? item : '',
                        }"
                      >
                        {{ index + 1 }}分
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="footer"></div> -->
      </div>
    </mobile-preview>
  </div>
</template>

<script>
import mobilePreview from "@/components/bussiness/mobile-preview/mobile-preview.vue";

export default {
  props: {
    isPreviewShow: {
      type: Boolean,
    },
    title: {
      type: String,
      default: "问卷详情",
    },
    detail: {
      type: Object,
      required: true,
    },
  },
  components: {
    mobilePreview,
  },
  data() {
    return {
      hasAnswerMap: {
        0: "未知",
        1: "已参与",
        2: "未参与",
      },
      scaleColorOps: [
        "#DCE6F7FF",
        "#CDDEFCFF",
        "#A4C3FAFF",
        "#367BF4B3",
        "#367BF4D1",
      ],
    };
  },
  computed: {
    filterList() {
      let { questionList } = this.detail;
      return questionList ? questionList.filter((v) => !v.isDeleted) : [];
    },
  },
  methods: {
    updateIsShow(val) {
      this.$emit("update:isPreviewShow", val);
    },
  },
};
</script>

<style lang="less" scoped>
.view-wrapper {
  .phone-wrap {
    height: 100%;
    .wrapper {
      height: calc(100% - 48px);
      &.isView {
        height: 100%;
      }
      .header {
        .header-title {
          padding: 22px 16px 0 16px;
          font-size: 16px;
          font-weight: bold;
          color: #1a1c34;
          line-height: 22px;
          text-align: center;
          word-wrap: break-word;
          word-break: break-all;
        }
        .header-subtitle {
          display: flex;
          justify-content: center;
          margin: 16px 0 40px 0;
          .sub-wrap {
            display: flex;
            .sub-wrap_icon {
              height: 20px;
              img {
                width: 20px;
                height: 20px;
              }
            }
            .sub-wrap_text {
              height: 20px;
              display: flex;
              align-items: center;
              font-size: 12px;
              font-weight: 400;
              color: #aaaaaa;
              margin: 0 4px 0 2px;
            }
            .sub-wrap_num {
              height: 20px;
              display: flex;
              align-items: center;
              font-size: 12px;
              font-weight: bold;
              color: #666666;
            }
          }
          .sub-tag {
            box-sizing: border-box;
            height: 20px;
            display: flex;
            align-items: center;
            background: #dbedff;
            border-radius: 4px;
            border: 1px solid #acd5ff;
            font-size: 12px;
            font-weight: 400;
            color: #007eff;
            padding: 0 6px;
            margin-left: 24px;
            &.hasAnswer {
              border: 1px solid #ffc7a4;
              background: #fff3eb;
              color: #fe6f16;
            }
          }
        }
      }
      .main {
        box-sizing: border-box;
        width: 100%;
        padding: 0 16px;
        .content-wrap {
          .content-wrap_title {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            line-height: 22px;
            margin-bottom: 12px;
            word-wrap: break-word;
            word-break: break-all;
          }
          .content-wrap_type {
            .single,
            .multi {
              margin-bottom: 40px;
              .option-wrap {
                position: relative;
                box-sizing: border-box;
                background: #ffffff;
                border-radius: 4px;
                // border: 2upx solid #acd5ff;
                border: 1px solid #c9cacb;
                overflow: hidden;
                &:not(:last-child) {
                  margin-bottom: 8px;
                }
                .option-progress {
                  box-sizing: border-box;
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 100%;
                  // width: 40%;
                  width: 0%;
                  background: #f5f5f5;
                  border-right: 1px solid #c9cacb;
                  border-radius: 4px 0upx 0upx 4px;
                }
                .option-text_copy,
                .option-text {
                  font-size: 16px;
                  font-weight: 400;
                  color: #333333;
                  line-height: 28px;
                  padding: 7px 40px 7px 16px;
                  word-wrap: break-word;
                  word-break: break-all;
                }
                .option-text_copy {
                  opacity: 0;
                }
                .option-text {
                  box-sizing: border-box;
                  width: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
                .option-icon {
                  display: none;
                  position: absolute;
                  top: 50%;
                  right: 16px;
                  transform: translateY(-50%);
                  width: 20px;
                  height: 20px;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                &.optionSelect {
                  // border: 2upx solid #c9cacb;
                  border: 1px solid #acd5ff;
                  .option-progress {
                    // background: #f5f5f5;
                    // border-right: 2upx solid #c9cacb;
                    background: #dbedff;
                    border-right: 1px solid #acd5ff;
                  }
                  .option-icon {
                    display: block;
                  }
                }
              }
            }
            .input {
              // width: calc(100% - 32px);
              margin-bottom: 40px;
              // border: 1px solid #c9cacb;
              // padding: 10px 16px;
              color: #333333;
              border-radius: 4px;
              .textarea {
                width: 100%;
                font-size: 16px;
                font-weight: 400;
                ::v-deep .el-textarea {
                  width: 100% !important;
                }
              }
            }
            .upload {
              margin-bottom: 40px;
              .upload-empty {
                font-size: 14px;
                color: #333333;
              }
            }
            .scale {
              margin-bottom: 40px;
              color: #333333;
              .option-wrap {
                .option-text {
                  font-size: 16px;
                  font-weight: 400;
                  color: #333333;
                  line-height: 24px;
                  // padding: 7px 40px 7px 16px;
                  padding: 7px 0;
                  word-wrap: break-word;
                  word-break: break-all;
                }
                .option-block {
                  display: flex;
                  align-items: center;
                  margin-bottom: 20px;
                  .block-color {
                    box-sizing: border-box;
                    flex: 1;
                    // width: 132px;
                    // height: 40px;
                    line-height: 36px;
                    text-align: center;
                    color: #43474fff;
                    background: #ffffff;
                    border-radius: 2px;
                    border: 1px solid #cbcbcb;
                    &:not(:last-child) {
                      margin-right: 6px;
                    }
                    &:last-child {
                      color: #000000d9;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
