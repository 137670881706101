<template>
  <div class="baseInfo">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      :hasHeader="false"
      @update="update"
    >
      <el-form-item
        label="问卷标题"
        :rules="[
          { required: true, message: '请输入问卷标题', trigger: 'blur' },
        ]"
        prop="title"
      >
        <v-input
          placeholder="请输入问卷标题"
          :maxlength="20"
          :width="width"
          v-model="form.title"
        ></v-input>
      </el-form-item>

      <el-form-item label="问卷说明" prop="description">
        <div id="editor">
          <v-ueditor :maxLength="20000" v-model="form.description" />
        </div>
      </el-form-item>

      <el-form-item
        label="回答次数"
        :rules="[
          { required: true, message: '请输入问卷标题', trigger: 'change' },
        ]"
        prop="awTimes"
      >
        <v-select
          placeholder="请选择回答次数"
          :options="awTimesOps"
          :width="width"
          v-model="form.awTimes"
        ></v-select>
      </el-form-item>

      <el-form-item
        label="户主限制"
        :rules="[
          { required: true, message: '请选择户主限制', trigger: 'blur' },
        ]"
        prop="isOnlyHouseOwner"
      >
        <v-radio
          :radioObj="isOnlyHouseOwnerOps"
          v-model="form.isOnlyHouseOwner"
        ></v-radio>
      </el-form-item>

      <el-form-item
        :label="sortLabel"
        :rules="[{ required: true, message: '请填写排序', trigger: 'blur' }]"
        prop="sortIndex"
      >
        <v-input-number
          v-model="form.sortIndex"
          :min="1"
          :width="width"
          :controls="true"
          controls-position="right"
        ></v-input-number>
      </el-form-item>
      <el-form-item
        label="投票结果是否公开"
        prop="isOpen"
        :rules="[
          {
            required: true,
            message: '请选择投票结果是否公开',
            trigger: 'blur',
          },
        ]"
      >
        <div class="check-panel">
          <v-radio :radioObj="isPublic" v-model="form.isOpen"></v-radio>
        </div>
      </el-form-item>
      <el-form-item
        label="用户类别"
        prop="authSpaceTypes"
        :rules="[
          { required: true, validator: spaceTypeValid, trigger: 'blur' },
        ]"
      >
        <div class="check-panel">
          <v-checkbox
            v-model="item.checked"
            v-for="(item, index) in userTypeOps"
            :key="index"
            :index="index"
            type="border"
            :label="item.label"
            @change="checkChange"
          />
        </div>
      </el-form-item>

      <el-form-item
        v-show="form.authSpaceTypes !== undefined"
        label="调查区域"
        prop="authSpaceIds"
        :rules="[
          { required: false, message: '请选择调查区域', trigger: 'blur' },
        ]"
      >
        <v-cascader
          placeholder="默认表示全部"
          :width="width"
          v-model="authSpaceIds"
          :options="sectionOps"
          :props="props"
          @change="sectionChange"
        />
      </el-form-item>

      <el-form-item
        label="问卷封面图"
        :rules="[
          {
            required: true,
            message: '请上传问卷封面图片',
            trigger: 'change',
          },
        ]"
        prop="imageUrls"
      >
        <v-upload
          tips="建议上传正方形图片"
          :limit="1"
          :imgUrls.sync="form.imageUrls"
        />
      </el-form-item>

      <el-form-item
        label="调查截止时间"
        :rules="[
          { required: true, message: '请输入调查截止时间', trigger: 'blur' },
        ]"
        prop="endDate"
      >
        <v-date-picker
          v-model="createTime"
          formatValue="yyyy-MM-dd HH:mm"
          format="yyyy-MM-dd HH:mm"
          type="datetimerange"
        ></v-date-picker>
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { getTopicInfo, topicAddOrEditURL, getBuildListURL } from "../api.js";
import {
  userTypeMap,
  setUserTypeOps,
  setAwTimesOps,
  isOnlyHouseOwnerOps,
  isPublic,
} from "../map.js";

export default {
  name: "baseInfo",
  props: {
    id: {
      type: [String, Number],
    },
    sortLabel: {
      type: [String],
      default: "问题排序",
    },
  },
  data() {
    return {
      isPublic,
      width: 220,
      submitConfig: {
        queryUrl: getTopicInfo,
        submitUrl: topicAddOrEditURL,
      },
      props: {
        multiple: true,
        label: "name",
        value: "id",
        children: "children",
      },
      form: {
        title: "", // 问卷标题
        isOnlyHouseOwner: 0, // 户主限制
        awTimes: undefined, // 回答次数：0-不限定，1-仅可以一次，2-一天一次
        imageUrls: "", // 问卷封面图
        startDate: "", // 计划开始时间
        endDate: "", // 计划结束时间
        allSpaces: 1, // 是否所有区域 1 是 0 否
        authSpaceIds: "", // 区域id集合
        sortIndex: undefined, //排序
        authSpaceTypes: undefined, // 用户类别id
        shelfSts: 0, // 0 待发布 1 发布 2 下架
        description: "", // 问卷说明
        quType: 1,
        isOpen: 0, // 投票结果是否公开
      },
      isOnlyHouseOwnerOps,
      awTimesOps: setAwTimesOps(), // 回答次数
      residentsOps: [], // 用户
      merchantsOps: [], // 商圈
      allOps: [], // 用户+商圈
      spacePathIds: [], // 添加treeIds的数据
      sectionOps: [], // 用于显示的数据
      authSpaceIds: [],
      userTypeOps: setUserTypeOps(),
      onOff: true, // 是否进入编辑
    };
  },
  computed: {
    createTime: {
      get() {
        return !this.form.startDate && !this.form.endDate
          ? []
          : [this.form.startDate, this.form.endDate];
      },
      set(val) {
        [this.form.startDate, this.form.endDate] = val || ["", ""];
      },
    },
    authSpaceTypes() {
      return this.form.authSpaceTypes;
    },
  },
  watch: {
    // 选中的用户类别集合
    userTypeOps: {
      handler(val) {
        console.log(val, this.form, this.onOff, "-----");
        if (this.onOff) {
          return;
        }
        const checkOps = val.filter((v) => v.checked);
        this.form.authSpaceTypes =
          checkOps.length > 0
            ? checkOps.map((v) => v.value).join(",")
            : undefined;
      },
      deep: true,
    },
    authSpaceTypes() {
      let types =
        this.form.authSpaceTypes !== undefined
          ? this.form.authSpaceTypes.split(",")
          : [];
      let result = [];
      if (types.includes("5") && types.includes("4")) {
        result = [...this.allOps];
      } else if (types.includes("5")) {
        result.push(...this.merchantsOps);
      } else if (types.includes("4")) {
        result.push(...this.residentsOps);
      }
      this.sectionOps = result;
    },
    // 选中的区域集合
    authSpaceIds: {
      handler(val) {
        if (this.onOff) {
          return;
        }
        this.form.authSpaceIds = val.map((a) => a[a.length - 1]).join(",");
        console.log(this.form.authSpaceIds);
      },
      deep: true,
    },
  },
  async created() {
    console.log(setAwTimesOps(), "setAwTimesOps");
    this.onOff = this.id !== undefined;
    // 4 居民 5 商户
    await Promise.all([
      this.getBuildList(4),
      this.getBuildList(5),
      this.getBuildList("4,5"),
    ]);
    console.log(this.spacePathIds, "_+-");
    if (this.id !== undefined) {
      this.$nextTick(() => {
        this.$refs.formPanel.getData({ topicId: this.id });
      });
    }
  },
  methods: {
    sectionChange(val) {
      console.log(val, this.form);
    },
    checkChange() {
      if (this.onOff) {
        return;
      }
      // 每次改变时, 清空默认选择项
      this.authSpaceIds = [];
    },
    spaceTypeValid(rule, value, callback) {
      if (value === undefined) {
        callback(new Error("请选择用户类别"));
      } else {
        callback();
      }
    },
    update(data) {
      this.$emit("updateData", data);
      Object.keys(this.form).forEach((key) => {
        if (key === "authSpaceTypes" && !data[key]) {
          this.form[key] = "";
          return;
        }
        this.form[key] = data[key] || this.form[key];
      });
      const ids = this.form.authSpaceIds
        ? this.form.authSpaceIds.split(",")
        : [];
      this.spacePathIds.forEach((item) => {
        if (ids.includes(item.id)) {
          this.authSpaceIds.push([...item.treeIds]);
        }
      });
      console.log(this.authSpaceIds, "this.authSpaceIds");
      let types = this.form.authSpaceTypes.split(",");
      this.userTypeOps.forEach((v) => {
        if (types.includes(v.value)) {
          v.checked = true;
        }
      });
      setTimeout(() => {
        this.onOff = false;
      }, 0);
    },
    async getBuildList(type) {
      let res = await this.$axios.get(
        `${getBuildListURL}?specifyCollectionTypes=${type}`
      );
      if (res.code === 200) {
        // type = 1 居民 2 商户
        // const maxLevel = type === 4 ? 3 : 2;
        let opt = this.dealDataAddParentIds(res.data);
        // 给res.data添加了treeIds
        // this.spacePathIds.push(...(res.data || []));
        // const ops = [
        //   ...(this.dealDataToTree(res.data, maxLevel)[0]
        //     ? this.dealDataToTree(res.data, maxLevel)[0].children
        //     : []),
        // ];
        console.log(res.data, opt, "opt---");
        // 4 用户 5 商圈
        if (type === 4) {
          this.residentsOps = opt;
        } else if (type === 5) {
          this.merchantsOps = opt;
        } else {
          this.allOps = opt;
        }
      }
    },
    submitBefore() {
      if (!this.form.optUser) {
        this.form.optUser = this.$store.state.app.userInfo.userId;
      }

      if (this.id) {
        this.form.id = this.id;
      }
      // 区域id集合有值表明非全选，反之就是全选
      this.form.allSpaces = this.form.authSpaceIds.length === 0 ? 1 : 0;
      return true;
    },
    submitSuccess(data) {
      this.$emit("updateData", data, true);
      return false;
    },
    // 数据添加 parentIds
    dealDataAddParentIds(data, ids = []) {
      data.forEach((v) => {
        if (v.children) {
          this.dealDataAddParentIds(v.children, [...ids, v.id]);
        } else {
          this.spacePathIds.push(v);
        }
        v.treeIds = [...ids, v.id];
      });
      return data;
    },
    // dealDataAddParentIds(res, maxLevel, curLevel = 1, parentId = "", ids = []) {
    //   res.forEach((item) => {
    //     if (item.parentId.trim() === parentId) {
    //       if (curLevel <= maxLevel) {
    //         if (curLevel > 2) {
    //           item.treeIds = [...ids, parentId];
    //         } else {
    //           item.treeIds = [];
    //         }
    //         this.dealDataAddParentIds(
    //           res,
    //           maxLevel,
    //           curLevel + 1,
    //           item.spaceId,
    //           item.treeIds
    //         );
    //       }
    //     }
    //   });
    // },
    // 数据处理成树结构数据
    dealDataToTree(res, maxLevel, curLevel = 1, parentId = "") {
      let result = [];
      res.forEach((item) => {
        if (item.parentId.trim() === parentId) {
          let build = {
            label: item.name,
            value: item.spaceId,
          };
          if (curLevel < maxLevel) {
            build.children = this.dealDataToTree(
              res,
              maxLevel,
              curLevel + 1,
              item.spaceId
            );
          }
          result.push(build);
        }
      });
      return result;
    },
  },
};
</script>
<style lang="less" scoped>
.baseInfo {
  box-sizing: border-box;
  height: calc(100% - 41px);
  overflow-x: hidden;
  overflow-y: auto;
  // padding: 0 0 20px 0;
  .check-panel {
    display: flex;
  }
}
</style>
